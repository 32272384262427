import {
    BooleanField,
    CreateButton,
    DatagridConfigurable, EditButton,
    List,
    SelectColumnsButton,
    TextField,
    TopToolbar
} from "react-admin";


const CustomListActions = () => (
    <TopToolbar>
        <CreateButton/>
        <SelectColumnsButton/>
    </TopToolbar>
);


export const WithdrawalMethodList = () => (
    <List
        sort={{field: 'id', order: 'ASC'}}
        // aside={<FilterSidebar/>}
        actions={<CustomListActions/>}
    >
        <DatagridConfigurable
            bulkActionButtons={null}
            // rowClick="expand"
            // expand={<OrderShow />}
            // expandSingle
            sx={{
                '& .RaDatagrid-thead': {
                    borderLeftColor: 'transparent',
                    borderLeftWidth: 5,
                    borderLeftStyle: 'solid',
                },
                '& .column-text': {
                    maxWidth: '18em',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                },
            }}
        >
            <TextField source="id" label="ID" sortable={false}/>
            <TextField source="method_name" label="Название" sortable={false}/>
            <TextField source="method_about" label="Детали" sortable={false}/>
            <BooleanField source="is_active" label="Активный" sortable={false}/>
            {/*<TextField source="sort_id" label="sort_id" sortable={false}/>*/}

            <EditButton/>
            {/*<DeleteButton/>*/}
        </DatagridConfigurable>
    </List>
)
