import {BooleanInput, Edit, required, SimpleForm, TextInput} from "react-admin";

export const WithdrawalMethodEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput label="Название" source="method_name" name="method_name" validate={required()}/>
            <TextInput label="Доп инфа" source="method_about" name="method_about"/>
            <BooleanInput label="Активный" source="is_active" name="is_active"/>
        </SimpleForm>
    </Edit>
);
