import {TextField, useRecordContext} from "react-admin";
import {getColor, make_id, moneyFormat} from "../../utils";

export function ColorMoneyField({source, color_name, checkCurrency=false}) {
    const record = useRecordContext();
    const recordSource = make_id();
    const color = getColor(color_name)
    let cur = "rub"
    if (checkCurrency)
        cur = record['payment_method'] === 'card_ua' ? 'uah' : 'rub'
    record[recordSource] = moneyFormat(record[source], cur)
    return (<TextField source={recordSource} style={{color: color}}/>)
}
