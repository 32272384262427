import loader from "../image/loader.svg";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import '../css/style.css';
import '../css/cashdone.css';
import {useEffect} from "react";
import logo from "../img/logo.png"
import logo_cashdone from "../img/cashdone.png"
import logo_backpage from "../img/backpage.png"

function PaymentApproved({orderData}) {
    useEffect(() => {
        if (orderData.url) {
            setTimeout(() => {
                window.location.replace(orderData.url)
            }, 3000)
        }
    })
    return (
        <div className="body-container">
            <div className="main2 main3 main4">
                <div className="main2-container">
                    <div className="main2-content">
                        <div className="top-bar">
                            <div className="logo-cont">
                                <img src={logo} width="46" height="46" alt=""/>
                                <p>APAYS</p>
                            </div>
                        </div>
                        <p className="check-pay">Проверяем оплату</p>
                        <div className="notfoundimg">
                            <img src={logo_cashdone} alt=""/>
                            <p className="notfoundtxt">Платеж выполнен!</p>
                        </div>
                        <div className="backtomainpage">
                            <a href={orderData.url}>
                                <img src={logo_backpage} alt=""/>
                                Вернуться на сайт продавца
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

// function PaymentApproved({orderData}) {
//     useEffect(()=>{
//         if(orderData.url){
//             setTimeout(()=>{
//                 window.location.replace(orderData.url)
//             }, 3000)
//         }
//     })
//     return (
//         <div className="container">
//             <div className="box">
//                 <div className="center">
//                     <b className="fs-20">Платеж выполнен</b>
//                 </div>
//                 <div className="loader-box">
//                     <CheckCircleIcon  color="success" sx={{ fontSize: 140 }}/>
//                 </div>
//
//                 <div className="center">
//                     <p>Если у вас есть вопросы, напишите нам:</p>
//                     <span className="fs-16 blue">{orderData.contact}</span>
//                 </div>
//
//             </div>
//         </div>
//     )
// }

export default PaymentApproved
