import {useEffect, useState} from "react";
import {strPadLeft, timestamp} from "../utils";

function Countdown({orderTime, timeout}) {
    const [timer, setTimer] = useState("")

    function setTime() {

        const deadline = (orderTime + timeout) - timestamp()
        if (deadline <= 0) {
            setTimer("Платеж отменен")
            return
        }
        const minutes = Math.floor(deadline / 60);
        const seconds = deadline - minutes * 60;
        const finalTime = strPadLeft(minutes, '0', 2) + ':' + strPadLeft(seconds, '0', 2);


        if (finalTime !== timer)
            setTimer(finalTime)

        setTimeout(setTime, 50)
    }

    useEffect(() => {
        setTime()
    })
    return timer
    return (
        <div className="center pt-10">
            <span className="deadline-timer">{timer}</span>
        </div>
    )
}

export default Countdown
