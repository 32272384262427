import {SERVER} from "../utils";

const authProvider = {
    login: ({ username, password }) =>  {
        const request = new Request(`${SERVER}/authenticate`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('auth', auth.key);
                return Promise.resolve({ redirectTo: '/admin' });
            })
            .catch(() => {
                throw new Error('Error')
            });
    },
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    checkAuth: () =>{
        console.log('checkAuth')
        // const isTokenExpired = token => Date.now() >= (JSON.parse(atob(token.split('.')[1]))).exp * 1000

        return localStorage.getItem('auth') === "bce2075ee8d73b51dd9bd34d3e82a16d" ? Promise.resolve() : Promise.reject()
    },
    checkError:  (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () =>
        Promise.resolve({
            id: 'user',
            fullName: 'Admin',
        }),
    getPermissions: () => Promise.resolve(''),
};

export default authProvider;
