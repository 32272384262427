import {BooleanInput, Create, NumberInput, required, SimpleForm, TextInput} from "react-admin";

export const TariffCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput label="tariff_name" source="tariff_name" name="tariff_name" validate={required()}/>
            <NumberInput label="fee_sbp" source="fee_sbp" name="fee_sbp" validate={required()}/>
            <NumberInput label="fee_ru" source="fee_ru" name="fee_ru" validate={required()}/>
            <NumberInput label="fee_ua" source="fee_ua" name="fee_ua" validate={required()}/>
            <BooleanInput label="is_default" source="is_default" name="is_default"/>

        </SimpleForm>
    </Create>
);
