import {
    BooleanField,
    CreateButton,
    DatagridConfigurable,
    DeleteButton,
    EditButton,
    List,
    SelectColumnsButton,
    TextField,
    TopToolbar
} from "react-admin";

const ListActions = () => (
    <TopToolbar>
        <SelectColumnsButton/>
        <CreateButton/>
    </TopToolbar>
);

export const TariffList = () => (
    <List
        actions={<ListActions/>}
        sort={{ field: 'id', order: 'DESC' }}
    >
        <DatagridConfigurable
        >
            <TextField source="id" label="ID" sortable={false}/>
            <TextField source="tariff_name" label="Название" sortable={false}/>
            <TextField source="fee_sbp" label="Комиссия СПБ" sortable={false}/>
            <TextField source="fee_ru" label="Комиссия RU" sortable={false}/>
            <TextField source="fee_ua" label="Комиссия UA" sortable={false}/>
            <BooleanField source="is_default" label="Тариф по умолчанию" sortable={false}/>
            <EditButton/>
            <DeleteButton/>
        </DatagridConfigurable>
    </List>
)
