import {
    CreateButton,
    DatagridConfigurable,
    DateField,
    DeleteButton, FilterList, FilterListItem, FilterLiveSearch,
    List,
    SelectColumnsButton,
    TextField,
    TopToolbar,
    useRecordContext
} from "react-admin";
import {ColorMoneyField} from "../components/fields/MoneyField";
import {getColor, make_id, moneyFormat} from "../utils";
import {ClientShow} from "../сlient/ClientShow";
import {WithdrawalShow} from "./WithdrawalShow";
import {TimestampField} from "../components/fields/TimestampField";
import {Card, CardContent} from "@mui/material";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";

const ListActions = () => (
    <TopToolbar>
        <SelectColumnsButton/>
        <CreateButton/>
    </TopToolbar>
);
export function StatusField({source}) {
    const statusObject = {
        0: "Создан",
        1: "Выполнен",
        2: "Отменен"
    }
    const record = useRecordContext();
    const recordSource = make_id();
    record[recordSource] = statusObject[record[source]]
    return (<TextField source={recordSource}/>)
}
function WithdrawalAmount({source}){
    const record = useRecordContext();
    const recordSource = make_id();
    record[recordSource] = moneyFormat(record[source])
    if(record.status === 0)
        return (<TextField source={recordSource} style={{color: getColor('yellow')}}/>)

    else if(record.status === 1)
        return (<TextField source={recordSource} style={{color: getColor('green')}}/>)

    else if(record.status === 2)
        return (<TextField source={recordSource} style={{color: getColor('red')}}/>)
}
export const WithdrawalList = () => (
    <List
        // actions={<ListActions/>}
        aside={<FilterSidebar/>}
        sort={{ field: 'id', order: 'DESC' }}
    >
        <DatagridConfigurable
            rowClick="expand"
            expand={<WithdrawalShow/>}
            expandSingle
        >
            <TextField source="id" sortable={false}/>
            <TextField source="client.project_name" label="Проект" sortable={false}/>
            <WithdrawalAmount source="amount" label="Сумма" sortable={false}/>
            <TextField source="requisites" label="Реквизиты" sortable={false}/>
            <TextField source="withdrawal_method.method_name" label="Способ" sortable={false}/>
            <TimestampField source="created_at" label="Время" showTime={true}/>
            <StatusField source="status" label="Статус" sortable={false}/>
            {/*<DeleteButton/>*/}
        </DatagridConfigurable>
    </List>
)


export const FilterSidebar = (() => {

    return (
        <Card sx={{order: -1, mr: 2, mt: 9, width: 220}}>
            <CardContent>
                <FilterLiveSearch source="q" label="Поиск"  name="search"/>

                <FilterList label="По статусу" icon={<SyncProblemIcon/>}>
                    <FilterListItem label="Создан" value={{status: 0}}/>
                    <FilterListItem label="Выполнен" value={{status: 1}}/>
                    <FilterListItem label="Отменен" value={{status: 2}}/>

                </FilterList>

            </CardContent>

        </Card>
    )
})
