import {BooleanInput, Create, NumberInput, required, SimpleForm, TextInput} from "react-admin";

export const ClientCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput label="project_name" source="project_name" name="project_name" validate={required()}/>
            <TextInput label="project_link" source="project_link" name="project_link" validate={required()}/>
            <NumberInput label="telegram_id" source="telegram_id" name="telegram_id"/>
            <BooleanInput label="commission_on_buyer" source="commission_on_buyer" name="commission_on_buyer"/>
            <TextInput label="callback_url" source="callback_url" name="callback_url"/>
            <TextInput label="success_url" source="success_url" name="success_url"/>
            <TextInput label="error_url" source="error_url" name="error_url"/>
        </SimpleForm>
    </Create>
);
