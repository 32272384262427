import {useState} from "react";
import {moneyFormat, uploadFile} from "../utils";
// import logo from "../image/logo.png";
// import {Button} from "@mui/material";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import Countdown from "../components/Countdown";
import '../css/style.css';
import '../css/page3.css';
import logo from "../img/logo.png"
import logo_line3 from "../img/Line3.png"
import logo_download from "../img/download.png"

export const FILE_TYPE_PHOTO = ['image/jpeg', 'image/pjpeg', 'image/png']

function UploadCheck({orderData}) {
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState("")
    const [fileType, setFileType] = useState(null)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const currency = orderData.payment_method === "card_ua" ? "uah" : "rub"
    const amount = moneyFormat(orderData.total_amount_on_currency, currency)


    function onClick() {
        console.log("uploadFile onClick")
        if (!file || buttonDisabled)
            return
        if (file && (FILE_TYPE_PHOTO.indexOf(fileType) === -1)) {
            setFile(null)
            alert("Недопустимый тип файла: " + fileType)
            return
        }
        setButtonDisabled(true)
        uploadFile(orderData.id, file,
            () => window.location.reload(),
            () => setButtonDisabled(false))
            .then(r => console.log("onClick result", r))
    }

    const Upload = () => {
        const f = file ? URL.createObjectURL(file) : logo_download
        return (
            <div className="download-block">
                <label htmlFor="file-upload" className="center w-100">
                    <img src={f} alt=""/>
                </label>
                <input
                    onChange={(e) => {
                        const val = e.target.value.split('\\')
                        setFileType(e.target.files[0].type)
                        setFileName(val[val.length - 1])
                        setFile(e.target.files[0])
                        e.target.value = ""
                        setButtonDisabled(false)


                    }}
                    id="file-upload"
                    type="file"
                />
            </div>
        )
    }


    return (
        <div className="body-container">
            <div className="main2 main3">
                <div className="main2-container">
                    <div className="main2-content">
                        <div className="top-bar">
                            <div className="logo-cont">
                                <img src={logo} width="46" height="46"/>
                                <p>APAYS</p>
                            </div>
                            <div className="buy-price">
                                <span className="price">{amount}</span>
                            </div>
                        </div>
                        <p className="alpha">{orderData.project_name}</p>
                        <div className="line">
                            <img src={logo_line3} alt=""/>
                        </div>
                        <p className="price-on">Отправь скрин чека перевода</p>
                        <Upload/>
                        <div className={`i-paid-block i-paid-block3 ${buttonDisabled ? 'hidden' : ''}`} onClick={onClick}>
                            <span className="i-paid" onClick={onClick}>Отправить</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

// function UploadCheck({orderData}) {
//     const [file, setFile] = useState(null)
//     const [fileName, setFileName] = useState("")
//     const [fileType, setFileType] = useState(null)
//     const [buttonDisabled, setButtonDisabled] = useState(true)
//     const currency = orderData.payment_method === "card_ua" ? "uah" : "rub"
//     const amount = moneyFormat(orderData.total_amount_on_currency, currency)
//
//
//     function onClick() {
//         if (!file)
//             return
//         if (file && (FILE_TYPE_PHOTO.indexOf(fileType) === -1)) {
//             setFile(null)
//             alert("Недопустимый тип файла: " + fileType)
//             return
//         }
//         setButtonDisabled(true)
//         uploadFile(orderData.id, file, ()=>{window.location.reload()}, ()=>setButtonDisabled(false))
//             .then(r => console.log("onClick result", r))
//     }
//
//     const Upload = () => {
//         return (
//             <>
//                 <label htmlFor="file-upload" className="custom-file-upload center">
//                     <div className="pt-10">
//                         <FilePresentIcon color="disabled" fontSize="large"/>
//                         <p className="lite-gray">{file ? fileName : "Прикрепить файл"}</p>
//                     </div>
//                 </label>
//                 <input
//                     onChange={(e) => {
//                         const val = e.target.value.split('\\')
//                         setFileType(e.target.files[0].type)
//                         setFileName(val[val.length - 1])
//                         setFile(e.target.files[0])
//                         e.target.value = ""
//                         setButtonDisabled(false)
//
//
//                     }}
//                     id="file-upload"
//                     type="file"
//                 />
//             </>
//         )
//     }
//
//
//     return (
//         <div className="container">
//             <div className="box">
//                 <div className="row w-100">
//                     <div className="w-50">
//                         <img src={logo} className="logo" alt="APay"/>
//                     </div>
//                     <div className="w-50 right top-block">
//                         <span className="top">Подтверждение</span>
//                     </div>
//                 </div>
//                 <div className="center">
//                     <b className="fs-24 blue">{orderData.project_name}</b>
//                 </div>
//                 <h2>{amount}</h2>
//                 <hr/>
//                 <h3>Отправьте чек перевода</h3>
//
//                 <Upload/>
//
//                 <Button
//                     onClick={onClick}
//                     variant="contained"
//                     disabled={buttonDisabled}
//                     fullWidth>Отправить
//                 </Button>
//                 <Countdown orderTime={orderData.order_time} timeout={orderData.timeout}/>
//             </div>
//         </div>
//     )
// }

export default UploadCheck
