import * as React from 'react';
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {ImageField} from "react-admin";
import {make_id, moneyFormat, SERVER} from "../utils";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

//
export default function CustomizedDialogs({record, source}) {
    const [open, setOpen] = React.useState(false);
    const recordSource = make_id();
    record[recordSource] = `${SERVER}/media/${record[source]}`
    console.log(record[recordSource])
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button
                variant="outlined"
                onClick={handleClickOpen}>

                Чек
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                // TransitionComponent={Transition}
                PaperProps={{
                    sx: {
                        minHeight: "98%"
                    }
                }}
            >
                {/*<DialogTitle sx={{ m: 0, p: 0 }} id="customized-dialog-title">*/}
                {/*    Modal title*/}
                {/*</DialogTitle>*/}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>

                    <ImageField source={recordSource} sx={{ '& img': { minWidth: "50vh", minHeight: "90vh", objectFit: 'contain' } }}/>
                    {/*<ImageField source="http://95.163.222.112/media/18.png" sx={{ '& img': { minWidth: "50vh", minHeight: "90vh", objectFit: 'contain' } }}/>*/}
                    {/*<img src="http://80.78.243.88/media/0956804b-13fa-4e0d-9f37-2ce20f2c4d12.png" title="First image" />*/}


                </DialogContent>

            </BootstrapDialog>
        </React.Fragment>
    );
}
