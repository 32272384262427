import {useNotify} from "react-admin";
import {notify_e, notify_w, SERVER} from "../utils";
import {Button} from "@mui/material";

export const ButtonPostRequest = ({endpoint, target, data, okMessage, label, variant, color, onSuccess=()=>{}}) => {
    const notify = useNotify();

    function showNotify(e) {

        if (e) {
            if (e["status"] === 'ok') {
                notify(okMessage, {type: 'success'});
                onSuccess()
            }
            else if (e["request_error"] === "invalid_status"){
                notify(`Ошибка статуса платежа`, notify_w);
            }
            else if (e["request_error"] === "insufficient_funds"){
                notify(`Недостаточно средств`, notify_w);
            }
            else if (e["request_error"] === "invalid_cur_pwd"){
                notify(`Текущий пароль введен неверно`, notify_e);
            }
            else if (e["request_error"] === "invalid_form_data"){
                notify(`Форма заполнена некорректно`, notify_e);
            }
            else {
                notify(e["request_error"], notify_e);
            }
        } else {
            notify(`Ошибка`, {type: 'error'});
        }
    }

    function onClick() {
        // return
        fetch(`${SERVER}/admin/${endpoint}/${target}`, {
            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => (showNotify(result)))
    }

    return (
        <Button variant={variant} onClick={onClick} color={color}> {label} </Button>

    );
};
