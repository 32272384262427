import loader from "../image/loader.svg";
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import '../css/style.css';
import '../css/cashnotfound.css';
import logo from "../img/logo.png"
import logo_notfound from "../img/notfound.png"
import logo_backpage from "../img/backpage.png"

function PaymentNotFound({orderData}) {
    return (
        <div className="body-container">
            <div className="main2 main3 main4">
                <div className="main2-container">
                    <div className="main2-content">
                        <div className="top-bar">
                            <div className="logo-cont">
                                <img src={logo} width="46" height="46"/>
                                <p>APAYS</p>
                            </div>
                        </div>
                        <p className="check-pay">Проверяем оплату</p>
                        <div className="notfoundimg">
                            <img src={logo_notfound} alt=""/>
                            <p className="notfoundtxt">Платеж не найден!</p>
                        </div>
                        {/*<div className="backtomainpage">*/}
                        {/*    <p>Если у вас есть вопросы, напишите нам:</p>*/}
                        {/*    <span className="fs-16 blue">{orderData.contact}</span>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

// function PaymentNotFound({orderData}) {
//     return (
//         <div className="container">
//             <div className="box">
//                 <div className="center">
//                     <b className="fs-20">Платеж не найден</b>
//                 </div>
//                 <div className="loader-box">
//                     <DoNotDisturbOnIcon color="warning" sx={{ fontSize: 140 }}/>
//                 </div>
//                 <div className="center">
//                     <p>Если у вас есть вопросы, напишите нам:</p>
//                     <span className="fs-16 blue">{orderData.contact}</span>
//                 </div>
//             </div>
//         </div>
//     )
// }

export default PaymentNotFound
