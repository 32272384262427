import {
    BooleanInput,
    Edit,
    NumberInput,
    ReferenceInput, required,
    SelectInput,
    SimpleForm,
    TextInput,
    useRecordContext
} from "react-admin";

const SelectInputDefaultValue = ({ optionText, source, ...rest }) => {
    const {choices} = rest;
    const record = useRecordContext();
    let defaultChoice = choices && choices.length===1? choices[0]:""
    console.log("defaultChoice", defaultChoice)
    console.log("rest", rest)
    console.log("source", source)
    console.log("record", record)
    return (
        <SelectInput optionText={optionText} defaultValue={defaultChoice.id} {...rest}/>
    )
}

export const ClientEdit= () => (
    <Edit>
        <SimpleForm>
            <TextInput label="project_name" source="project_name" name="project_name" validate={required()}/>
            <TextInput label="project_link" source="project_link" name="project_link" validate={required()}/>
            <NumberInput label="telegram_id" source="telegram_id" name="telegram_id"/>
            <BooleanInput label="Комиссию платит покупатель" source="commission_on_buyer" name="commission_on_buyer"/>
            <BooleanInput label="Активен" source="is_active" name="is_active"/>
            <BooleanInput label="P2p API" source="is_p2p_api" name="is_p2p_api"/>
            {/*<SelectInput source="commission" choices={[*/}
            {/*    { id: 'merchant', name: 'merchant' },*/}
            {/*    { id: 'user', name: 'user' },*/}
            {/*]} />*/}

            <ReferenceInput source="tariff_id" reference="crud/tariff" name="tariff">
                <SelectInput  optionText="tariff_name" source="tariff_id"/>
            </ReferenceInput>
            <TextInput label="callback_url" source="callback_url" name="callback_url"/>
            <TextInput label="success_url" source="success_url" name="success_url"/>
            <TextInput label="error_url" source="error_url" name="error_url"/>
        </SimpleForm>
    </Edit>
);
