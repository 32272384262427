import {NumberInput, Edit, BooleanInput, SimpleForm, TextInput, required} from "react-admin";

export const TariffEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput label="Название" source="tariff_name" name="tariff_name" validate={required()} />
            <NumberInput label="Комиссия СПБ" source="fee_sbp" name="fee_sbp" validate={required()}/>
            <NumberInput label="Комиссия RU" source="fee_ru" name="fee_ru" validate={required()}/>
            <NumberInput label="Комиссия UA" source="fee_ua" name="fee_ua" validate={required()}/>
            <BooleanInput label="Тариф по умолчанию" source="is_default" name="is_default"/>
        </SimpleForm>
    </Edit>
);
