// import logo from "../image/logo.png"
import {cardFormat, copyToClipboard, moneyFormat, strPadLeft, timestamp} from "../utils";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Button} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {emit} from "../sockets";
import parsePhoneNumber from "libphonenumber-js";
import Countdown from "../components/Countdown";
import {Push, showPush} from "../components/Push";
import '../css/style.css';
import '../css/page2.css';
import logo from "../img/logo.png"
import logo_Line3 from "../img/Line3.png"
import logo_sber from "../img/sber.png"
import logo_copy from "../img/copy.png"
import logo_mark from "../img/mark.png"
import logo_comment from "../img/comment.png"
import logo_clock from "../img/clock.png"

function Requisites({orderData}) {
    const currency = orderData.payment_method === "card_ua" ? "uah" : "rub"
    const amount = moneyFormat(orderData.total_amount_on_currency, currency)
    const copyIconRef = useRef()
    const markIconRef = useRef()

    function getMethodName(n) {
        switch (n) {
            case "spb":
                return "Оплата по СПБ"
            case "card_ru":
                return "Оплата картой (Россия)"
            case "card_ua":
                return "Оплата карта (Украина)"
            default:
                return ""
        }
    }

    function getTextMethod(n) {
        if (n === "spb")
            return "по номеру"
        else
            return "на карту"
    }

    function onClick() {
        const data = {
            orderId: orderData.id,
        }
        emit({
            event: 'payment_done', data: data, onSuccess: (e) => window.location.reload()
        })
    }

    function getRequisites() {
        console.log(orderData)
        if (orderData.payment_method === "sbp") {
            try {
                const phoneNumber = parsePhoneNumber(orderData.requisites, 'RU')
                return phoneNumber.formatInternational()
            } catch (e) {
                return orderData.requisites
            }
        } else {
            return cardFormat(orderData.requisites)
        }
    }

    function copy() {
        // showPush("success", "Скопировано", 800)
        toggleIcons()
        copyToClipboard(orderData.requisites.replace(/[\D]+/g, ''))
    }

    function toggleIcons() {
        copyIconRef.current.classList.add("hidden");
        copyIconRef.current.style.display = "none";
        markIconRef.current.style.display = "block";
        markIconRef.current.classList.remove("hidden");


        setTimeout(function () {
            markIconRef.current.classList.add("hidden");
            markIconRef.current.style.display = "none";
            copyIconRef.current.style.display = "block";
            copyIconRef.current.classList.remove("hidden");
        }, 1000);
    }


    return (
        <div className="body-container">
            <div className="main2">
                <div className="main2-container">
                    <div className="main2-content">
                        <div className="top-bar">
                            <div className="logo-cont">
                                <img src={logo} width="46" height="46"/>
                                <p>APAYS</p>
                            </div>
                            <div className="buy-price">
                                <span className="price">{amount}</span>
                            </div>
                        </div>
                        <p className="alpha">{orderData.project_name}</p>
                        <div className="line">
                            <img src={logo_Line3} alt=""/>
                        </div>
                        <p className="price-on">
                            Переведите <span className="price-on-id">{amount}</span> {getTextMethod(orderData.payment_method)}:
                        </p>
                        <div className="sbp-pay-block">
                            <div className="sbp-card-number" onClick={copy}>
                                <div className="left-sbp-card-nubmber">
                                    <img src={logo_sber} alt=""/>
                                    <p className="card-id">{getRequisites()}</p>
                                    {/*<p className="number-card-text">Номер</p>*/}
                                </div>
                                <a href="#" className="copy">
                                    <img id="copy" ref={copyIconRef} src={logo_copy}/>
                                    <img id="mark" ref={markIconRef} src={logo_mark}/>
                                </a>
                            </div>
                            <div className="sbp-card-number spb-comment">
                                <div className="left-sbp-card-nubmber">
                                    <img src={logo_comment} alt=""/>
                                    {/*<p className="comment-id">Сбербанк</p>*/}
                                    <p className="number-card-text">{orderData.comment}</p>
                                    <span className="invisible-comment"></span>
                                </div>
                                {/*<a href="#" className="copy">*/}
                                {/*    <img id="copy2" src={logo_copy}/>*/}
                                {/*    <img id="mark2" src={logo_mark}/>*/}
                                {/*</a>*/}
                            </div>
                        </div>
                        <div className="i-paid-block" onClick={onClick}>
                            <span className="i-paid" onClick={onClick}>Я оплатил!</span>
                        </div>
                        <div className="timer-block timer-block-page2">
                            <div className="timer-content">
                                <img src={logo_clock}/>
                                <p id="timer"><Countdown orderTime={orderData.order_time} timeout={orderData.timeout}/>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


// function Requisites2({orderData}) {
//     const currency = orderData.payment_method === "card_ua" ? "uah" : "rub"
//     const amount = moneyFormat(orderData.total_amount_on_currency, currency)
//     function getMethodName(n) {
//         switch (n) {
//             case "spb":
//                 return "Оплата по СПБ"
//             case "card_ru":
//                 return "Оплата картой (Россия)"
//             case "card_ua":
//                 return "Оплата карта (Украина)"
//             default:
//                 return ""
//         }
//     }
//     function getTextMethod(n){
//         if (n === "spb")
//             return "по номеру телефона"
//         else
//             return "на карту"
//     }
//     function onClick(){
//         const data = {
//             orderId: orderData.id,
//         }
//         emit({
//             event: 'payment_done', data: data, onSuccess: (e) => window.location.reload()
//         })
//     }
//     function getRequisites(){
//         if(orderData.payment_method === "spb"){
//             try {
//                 const phoneNumber = parsePhoneNumber(orderData.requisites)
//                 return phoneNumber.formatInternational()
//             }
//             catch (e) {
//                 return orderData.requisites
//             }
//         }
//         else{
//             return cardFormat(orderData.requisites)
//         }
//     }
//
//     function copy(){
//         showPush("success", "Скопировано", 800)
//         copyToClipboard(orderData.requisites.replace(/[\D]+/g, ''))
//     }
//
//     return (
//         <div className="container">
//             <Push/>
//             <div className="box">
//                 <div className="row w-100">
//                     <div className="w-50">
//                         <img src={logo} className="logo" alt="APay"/>
//                     </div>
//                     <div className="w-50 right top-block">
//                         <span className="top">Оплата заказа</span>
//                     </div>
//                 </div>
//                 <div className="center">
//                     <b className="fs-24 blue">{orderData.project_name}</b>
//                 </div>
//                 <h2>{amount}</h2>
//                 <hr/>
//                 <h3>{getMethodName(orderData.payment_method)}</h3>
//
//                 <p>Переведите {amount} {getTextMethod(orderData.payment_method)}:</p>
//                 <div className="row w-100 pt-10">
//                     <div className="w-90">
//                         <b>{getRequisites()}</b>
//                     </div>
//                     <div className="w-10 right">
//                         <ContentCopyIcon
//                             onClick={copy}
//                             color="disabled"
//                             sx={{fontSize: 20}}
//                         />
//                     </div>
//                 </div>
//                 <p className="comment">{orderData.comment}</p>
//                 <Button
//                     variant="contained"
//                     onClick={onClick}
//                     fullWidth>Я оплатил</Button>
//
//                 <Countdown orderTime={orderData.order_time} timeout={orderData.timeout}/>
//             </div>
//         </div>
//     )
// }

export default Requisites
