import {useNotify, useRecordContext, useRefresh} from "react-admin";
import {Box, Card, CardContent, Stack} from "@mui/material";
import {ButtonPostRequest} from "../components/ButtonPost";
import TextField from '@mui/material/TextField';
import {useState} from "react";
import CustomizedDialogs from "../components/modal";
import {copyToClipboard, notify_s} from "../utils";


function copy(text, notify) {
    copyToClipboard(text)
    notify(`Скопировано`, notify_s);
}

function Requisites({record}) {
    const [requisites, setRequisites] = useState("")
    const [comment, setComment] = useState("")
    const refresh = useRefresh();
    return (
        <Card sx={{width: 'auto'}}>
            <CardContent>
                <Stack
                    direction="row"
                    justifyContent="right"
                    alignItems="right"
                    spacing={2}
                >

                    <TextField label="Коммент" variant="outlined" value={comment}
                               onChange={event => setComment(event.target.value)} fullWidth/>
                    <TextField label="Реквизиты" variant="outlined" value={requisites}
                               onChange={event => setRequisites(event.target.value)}/>

                    <ButtonPostRequest
                        endpoint="send_requisites"
                        target={record.id}
                        data={{requisites: requisites, comment: comment}}
                        label="Отправить"
                        okMessage="Отправлено"
                        color="warning"
                        variant="outlined"
                        onSuccess={refresh}
                    />
                </Stack>
            </CardContent>
        </Card>
    )
}

function Confirm({record}) {
    const refresh = useRefresh();
    const notify = useNotify();
    return (
        <Card sx={{width: 'auto'}}>
            <CardContent>
                <span>{record.comment ? `Коммент ${record.comment}` : ""}</span>
                <br/>
                <span>
                    UUID:
                    <span
                        className="blue"
                        onClick={() => copy(record.uuid, notify)}>
                        {record.uuid}
                    </span>
                </span>
                <Stack
                    direction="row"
                    justifyContent="right"
                    alignItems="right"
                    spacing={2}
                >

                    {record.filename
                        ? <CustomizedDialogs record={record} source={"filename"} label="Чек" sortable={false}/>
                        : null
                    }
                    {!record.api_payment_id
                        ? (<>
                            <ButtonPostRequest
                                endpoint="set_order_status"
                                target={record.id}
                                data={{type: 5}}
                                label="Подтвердить"
                                okMessage="Платеж подтвержден"
                                color="success"
                                variant="outlined"
                                onSuccess={refresh}
                            />

                            <ButtonPostRequest
                                endpoint="set_order_status"
                                target={record.id}
                                data={{type: 6}}
                                label="Отклонить"
                                okMessage="Платеж отменен"
                                color="error"
                                variant="outlined"
                                onSuccess={refresh}
                            />
                        </>)
                        : null
                    }
                </Stack>
            </CardContent>
        </Card>
    )
}

export const OrderShow = () => {
    const record = useRecordContext();
    const notify = useNotify();
    if (!record) return null;

    // function copy(text) {
    //     copyToClipboard(text)
    //     notify(`Скопировано`, notify_s);
    // }

    function getControl() {
        if (record.status === 4)
            return <Confirm record={record}/>
        else if (record.status === 1)
            if (record.api_payment_id === null)
                return <Requisites record={record}/>
            else
                return <p>Платеж выполняется через p2p маркет</p>
        else
            return (
                <Box>
                    <span>{record.comment ? `Коммент ${record.comment}` : ""}</span>
                    <br/>
                    <span>
                        UUID:
                        <span
                            className="blue"
                            onClick={() => copy(record.uuid, notify)}>
                            {record.uuid}
                        </span>
                    </span>
                </Box>
            )
    }


    return getControl()
}

