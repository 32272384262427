import {useRecordContext, useRefresh} from "react-admin";
import {Box, Card, CardContent, Stack} from "@mui/material";
import TextField from "@mui/material/TextField";
import {ButtonPostRequest} from "../components/ButtonPost";
import {useState} from "react";

export const WithdrawalShow = () => {
    const record = useRecordContext();
    const refresh = useRefresh();
    if (!record) return null;

    if (record.status === 0) {
        return (
            <Card sx={{width: 'auto'}}>
                <CardContent>
                    <Stack
                        direction="row"
                        justifyContent="right"
                        alignItems="right"
                        spacing={2}
                    >

                        <ButtonPostRequest
                            endpoint="client_withdrawal_set_status"
                            target={record.id}
                            data={{status: 1}}
                            label="Подтвердить"
                            okMessage="Вывод подтвержден"
                            color="success"
                            variant="outlined"
                            onSuccess={refresh}
                        />
                        <ButtonPostRequest
                            endpoint="client_withdrawal_set_status"
                            target={record.id}
                            data={{status: 2}}
                            label="Отклонить"
                            okMessage="Вывод отменен"
                            color="error"
                            variant="outlined"
                            onSuccess={refresh}
                        />
                    </Stack>
                </CardContent>
            </Card>
        )
    }
    else {
        return <p>Вывод {record.status === 1 ? 'подтвержден' : 'отменен'}</p>
    }
}


