import {Admin, CustomRoutes, defaultDarkTheme, defaultLightTheme, Layout, Menu, Resource} from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import {forceRefresh, SERVER} from "./utils";
import {TariffList} from "./tariff/TariffList";
import {ClientList} from "./сlient/ClientList";
import {TariffCreate} from "./tariff/TariffCreate";
import {TariffEdit} from "./tariff/TariffEdit";
import {ClientEdit} from "./сlient/ClientEdit";
import {ClientCreate} from "./сlient/ClientCreate";
import {WithdrawalList} from "./withdrawal/WithdrawalList";
import {OrderList} from "./order/OrderList";
import {useEffect} from "react";
import {socket} from "./sockets";
import {ClientShow} from "./сlient/ClientShow";
import {WithdrawalShow} from "./withdrawal/WithdrawalShow";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import PersonIcon from '@mui/icons-material/Person';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import authProvider from "./auth/authProvider";
import {Route} from "react-router-dom";
import Settings from "./settings";
import SettingsIcon from '@mui/icons-material/Settings';
import {WithdrawalMethodList} from "./withdrawal_method/WithdrawalMethodList";
import {WithdrawalMethodEdit} from "./withdrawal_method/WithdrawalMethodEdit";
import {WithdrawalMethodCreate} from "./withdrawal_method/WithdrawalMethodCreate";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';


export const MyMenu = () => (
    <Menu>

        <Menu.ResourceItem name="crud/tariff"/>
        <Menu.ResourceItem name="crud/client"/>
        <Menu.ResourceItem name="crud/order"/>
        <Menu.ResourceItem name="crud/withdrawal"/>
        <Menu.ResourceItem name="crud/withdrawal_method"/>
        <Menu.Item to="/admin/settings" primaryText="Настройки" leftIcon={<SettingsIcon/>}/>
    </Menu>
);
export const MyLayout = props => <Layout {...props} menu={MyMenu}/>;

function AdminPage() {
    useEffect(() => {
        localStorage.setItem('access', "eff2fefb296c709ffc3c875f9bf76923")
        socket.on('adm_refresh', (e) => forceRefresh());
    }, [])
    return (
        <Admin
            basename="/admin"
            dataProvider={simpleRestProvider(SERVER)}
            authProvider={authProvider}
            // dashboard={Dashboard}
            title="U-pay"
            // layout={MainLayout}
            // theme={nanoLightTheme}
            // darkTheme={nanoDarkTheme}
            theme={defaultLightTheme}
            darkTheme={defaultDarkTheme}
            defaultTheme="dark"
            layout={MyLayout}
            // disableTelemetry
            // requireAuth
        >
            <CustomRoutes>
                <Route path="/settings" element={<Settings/>}/>
            </CustomRoutes>

            <Resource name="crud/tariff"
                      options={{label: 'Тарифы'}}
                      list={TariffList}
                      create={TariffCreate}
                      edit={TariffEdit}
                      icon={LocalOfferIcon}
            />
            <Resource name="crud/client"
                      options={{label: 'Клиенты'}}
                      list={ClientList}
                      create={ClientCreate}
                      edit={ClientEdit}
                      show={ClientShow}
                      icon={PersonIcon}
            />
            <Resource name="crud/order"
                      options={{label: 'Платежи'}}
                      list={OrderList}
                      icon={RequestPageIcon}
            />
            <Resource name="crud/withdrawal"
                      options={{label: 'Заявки на вывод'}}
                      list={WithdrawalList}
                      show={WithdrawalShow}
                      icon={AccountBalanceIcon}
            />
            <Resource name="crud/withdrawal_method"
                      options={{label: 'Способы вывода'}}
                      list={WithdrawalMethodList}
                      edit={WithdrawalMethodEdit}
                      create={WithdrawalMethodCreate}
                      icon={AddBusinessIcon}
            />

        </Admin>
    );
}

export default AdminPage;
