import {emit} from "../sockets";
import logoSbp from "../image/sbp.svg"
import logoCard from "../image/card.svg"
import Countdown from "../components/Countdown";
import {Push, showPush} from "../components/Push";
import {type} from "@testing-library/user-event/dist/type";
import {useEffect, useState} from "react";
import '../css/style.css';
import logo from "../img/logo.png"
import logo_ru from "../img/ru.png"
import logo_ua from "../img/ua.png"
import logo_sbp from "../img/sbp.png"
import logo_clock from "../img/clock.png"
import {moneyFormat} from "../utils";
import logo_notfound from "../img/notfound.png";
import logo_sorry from "../img/sorry.svg";


function StandartPage({orderData, onClick}) {
    return (
        <div className="body-container">
            <div className="main">
                <div className="main-container">
                    <div className="main-content">
                        <div className="top-bar">
                            <div className="logo-cont">
                                <img src={logo} width="46" height="46"/>
                                <p>APAYS</p>
                            </div>
                            <div className="buy-price">
                                <span className="price">{moneyFormat(orderData.amount)}</span>
                            </div>
                        </div>
                        <p className="chose-paymethod">Выберите способ оплаты</p>
                        <div className="method-block">
                            <div className="top-method-block">
                                <div className="ru-card btn" onClick={() => onClick('card_ru')}>
                                    <div className="ru-line">
                                        <img src={logo_ru} alt=""/>
                                        <p className="method-name">Карта России</p>
                                        <p className="percent">7%</p>
                                    </div>
                                </div>
                                <div className="ru-card ua-card btn" onClick={() => onClick('card_ua')}>
                                    <div className="ru-line">
                                        <img src={logo_ua} alt=""/>
                                        <p className="method-name">Карта Украины</p>
                                        <p className="percent">10%</p>
                                    </div>
                                </div>
                            </div>
                            <div className="spb btn" onClick={() => onClick('sbp')}>
                                <div className="spb-line">
                                    <img src={logo_sbp} alt=""/>
                                    <p className="method-name">Система Быстрых Платежей</p>
                                    <p className="percent">7%</p>
                                </div>
                            </div>
                        </div>
                        <div className="timer-block">
                            <div className="timer-content">
                                <img src={logo_clock}/>
                                <p id="timer"><Countdown orderTime={orderData.order_time} timeout={orderData.timeout}/>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function MethodChangedPage({method}) {
    const method_map = {
        "rub": "Карта России",
        "uah": "Карта Украины",
        "sbp": "СБП",
    }
    return (
        // <div className="body-container">
        //     <div className="main">
        //         <div className="main-container">
        //             <div className="main-content">
        //                 <div className="top-bar">
        //                     <div className="logo-cont">
        //                         <img src={logo} width="46" height="46"/>
        //                         <p>APAYS</p>
        //                     </div>
        //                     <div className="buy-price">
        //                         <span className="price">{moneyFormat(orderData.amount)}</span>
        //                     </div>
        //                 </div>
        //                 <p className="chose-paymethod">Способ оплаты оплаты будет именен на «{method_map[method]}»</p>
        //                 <p>В данный момент мы не можем принять средства выбранным Вами способом.</p>
        //                 <div className="timer-block">
        //                     <div className="timer-content">
        //                         <img src={logo_clock}/>
        //                         <p id="timer"><Countdown orderTime={orderData.order_time} timeout={orderData.timeout}/>
        //                         </p>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div className="body-container">
            <div className="main2 main3 main4">
                <div className="main2-container">
                    <div className="main2-content">
                        <div className="top-bar">
                            <div className="logo-cont">
                                <img src={logo} width="46" height="46"/>
                                <p>APAYS</p>
                            </div>
                        </div>
                        <p className="check-pay">Способ оплаты будет именен на «{method_map[method]}»</p>
                        <br/>
                        <p className="sorry">В данный момент мы не можем принять средства выбранным Вами способом.</p>
                        <div className="sorryimg">
                            <img width="200px" src={logo_sorry} alt=""/>
                        </div>
                        <div className="i-paid-block" onClick={()=>window.location.reload()}>
                            <span className="i-paid" onClick={()=>window.location.reload()}>Продолжить</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function SelectMethod({orderData}) {
    console.log(orderData)
    const [standartPage, setStandartPage] = useState(true)
    const [newMethod, setNewMethod] = useState()

    function checkResponse(response) {
        console.log("checkResponse", response)
        if (response.type === "force_method") {
            setNewMethod(response.switch_to)
            setStandartPage(false)
        } else{
            window.location.reload()
        }

    }

    function onClick(method) {
        const data = {
            orderId: orderData.id,
            method: method,
        }
        emit({
            event: 'set_method', data: data, onSuccess: (e) => checkResponse(e)
        })
    }

    return standartPage
        ? (<StandartPage orderData={orderData} onClick={onClick}/>)
        : (<MethodChangedPage method={newMethod}/>)
}


export default SelectMethod
