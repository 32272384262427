import {useEffect, useState} from "react";
import {emit, socket} from "../sockets";
import {useSearchParams} from "react-router-dom";
import SelectMethod from "./SelectMethod";
import WaitRequisites from "./WaitRequisites";
import Requisites from "../order/Requisites";
import UploadCheck from "./UploadCheck";
import WaitCheck from "./WaitCheck";
import PaymentApproved from "./PaymentApproved";
import PaymentDeclined from "./PaymentDeclined";
import PaymentNotFound from "./PaymentNotFound";

export function Form() {
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPage] = useState(null);
    const orderUuid = searchParams.get('id')
    useEffect(() => {
        console.log("useEffect--")
        function onConnect() {
            let is_init = false
            setIsConnected(true)

            if (!is_init) {
                console.log(`connect ${socket.id}`);
                localStorage.setItem("latest_session_id", socket.id)

                // silentEmit({event: 'get_workers', callback: e => adminStore.update_samples(e)})
                // silentEmit({event: 'get_cards', callback: e => adminStore.update_cards(e)})

                is_init = true
            }
        }

        function onDisconnect(reason) {
            setIsConnected(false);
            console.log("reason--", reason)

        }
        function callback(orderData){
            switch (orderData.status){
                case 0:
                    setPage(<SelectMethod orderData={orderData}/>)
                    break;
                case 1:
                    setPage(<WaitRequisites orderData={orderData}/>)
                    break;
                case 2:
                    setPage(<Requisites orderData={orderData}/>)
                    break;
                case 3:
                    setPage(<UploadCheck orderData={orderData}/>)
                    break;
                case 4:
                    setPage(<WaitCheck orderData={orderData}/>)
                    break;
                case 5:
                    setPage(<PaymentApproved orderData={orderData}/>)
                    break;
                case 6:
                    setPage(<PaymentDeclined orderData={orderData}/>)
                    break;
                case 7:
                    setPage(<PaymentNotFound orderData={orderData}/>)
                    break;
                default:
                    return setPage(<PaymentNotFound orderData={orderData}/>)
            }
        }
        // function redirect(data){
        //     setTimeout(()=>{
        //         window.location.replace(data.url)
        //     }, data.delay)
        // }
        // socket.connect()
        function reload(){
            console.log("reload")
            window.location.reload()
        }
        socket.on('reload', (e)=>reload());
        // socket.on('redirect', redirect);
        socket.on('connect', (e)=>onConnect(e));
        socket.on('disconnect', (reason) => onDisconnect(reason));

        const data = {orderUuid: orderUuid}
        console.log(data)
        function getOrderData(){
            console.log("getOrderData")
            emit({
                event: 'get_order', data: data, onSuccess: callback, onError: () => setTimeout(getOrderData, 1000)
            })
        }
        getOrderData()

    }, [])

    return (
        isConnected
            ? page
            : <h4 className="center">Connected...</h4>
    )
}
