import {regex, required, useNotify, useRecordContext, useRefresh} from "react-admin";
import {Box, Card, CardContent, Stack} from "@mui/material";
import TextField from "@mui/material/TextField";
import {ButtonPostRequest} from "../components/ButtonPost";
import {useState} from "react";
import {copyToClipboard, notify_s, notify_w} from "../utils";

export const ClientShow = () => {
    const [sum, setSum] = useState('')
    const [paymentMethod, setPaymentMethod] = useState('')
    const [requisites, setRequisites] = useState('')
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();
    if (!record) return null;

    function onSuccess() {
        setSum('')
        refresh()
    }

    function _setSum(event) {
        const v = parseInt(event.target.value)
        if (isNaN(v))
            setSum('0')
        else
            setSum(v.toString())

    }

    function copy(text) {
        copyToClipboard(text)
        notify(`Скопировано`, notify_s);
    }

    return (
        <Card sx={{width: 'auto'}}>
            <CardContent>
                <span>API key: <span
                    className="blue"
                    onClick={() => copy(record.secret_key)}
                >{record.secret_key}</span></span>

                <Stack
                    direction="row"
                    justifyContent="right"
                    alignItems="right"
                    spacing={2}
                >

                    {/*<TextField label="Метод" variant="outlined" value={paymentMethod}*/}
                    {/*           onChange={event => setPaymentMethod(event.target.value)}*/}
                    {/*/>*/}
                    {/*<TextField label="Реквизиты" variant="outlined" value={requisites}*/}
                    {/*           onChange={event => setRequisites(event.target.value)}*/}
                    {/*           validate={required()}*/}
                    {/*/>*/}
                    {/*<TextField label="Сумма" variant="outlined" value={sum}*/}
                    {/*           onChange={event => _setSum(event)}*/}
                    {/*           validate={[required(), regex(/^\d+$/)]}*/}
                    {/*/>*/}

                    {/*<ButtonPostRequest*/}
                    {/*    endpoint="client_withdrawal"*/}
                    {/*    target={record.id}*/}
                    {/*    data={{sum: sum, method: paymentMethod, requisites: requisites}}*/}
                    {/*    label="Вывести"*/}
                    {/*    okMessage="Вывод подтвержден"*/}
                    {/*    color="warning"*/}
                    {/*    variant="outlined"*/}
                    {/*    onSuccess={onSuccess}*/}
                    {/*/>*/}
                </Stack>
            </CardContent>
        </Card>
    )
}


